.promo-container {
    padding: 40px;
    border-radius: 10px;
    background-color: #031e25;
    color: white;
    font-family: Arial, sans-serif;
    max-width: 1200px;
}

.promo-title {
    font-size: 28px;
    color: rgb(226, 226, 226);
    text-align: left;
    margin-bottom: 10px;
    font-weight: 400;
}

.promo-description {
    text-align: left;
    color: #bbbbbb;
    margin-bottom: 30px;
    font-size: 14px;
}

.promo-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* gap: 20px; */
    /* border: 2px solid red; */
}

/* Left Section */
.promo-left {
    flex: 1;
    /* border: 2px solid blue; */
}

.promo-box {
    background-color: #03111693;
    padding: 20px;
    border-radius: 10px;
    /* margin-bottom: 20px; */
    /* max-width: 45%; */
    width: 100%;
    max-width: 600px;
    text-align: left;
    margin-right: 10px;
}

.promo-box-title {
    display: flex;
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
    white-space: nowrap;
}

.promo-steps {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.promo-step {
    text-align: center;
    color: #ccc;
    text-align: left;
}

.promo-step-title {
    color: white;
    padding-bottom: 4px;
}

.promo-steps-numbers {
    background-color: #1b3b4f;
    color: white;
    font-size: 18px;
    font-weight: bold;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 10px;
}

.promo-button {
    background-color: #30fedf81;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.promo-button:hover {
    background-color: #0056b3;
}

.promo-box-footer {
    color: #aaa;
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
}

/* Right Section */
.promo-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    /* border: 2px solid green; */
    text-align: left;
}

.promo-info {
    /* background-color: #102a3a; */
    padding-left: 20px;
    margin-bottom: 28px;
    border-radius: 10px;
}

.promo-info h3 {
    color: rgb(226, 226, 226);
    margin-bottom: 10px;
    font-weight: 500;
}

.promo-info p {
    color: #bbbbbb;
    font-size: 13px;
}

.promo-icon {
    font-size: 24px;
    color: #30FEDF;
    margin-bottom: 10px;
}

.promo-info-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Terms and Conditions Section */
.promo-terms {
    margin-top: 40px;
    padding: 20px;
    border-radius: 10px;
    /* background-color: #02151a; */
    color: white;
    text-align: left;
}

.terms-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: rgb(226, 226, 226);
    white-space: nowrap;
    display: block;
}

.terms-section {
    margin-bottom: 30px;
}

.terms-section h3 {
    font-size: 18px;
    color: #30fedf;
    margin-bottom: 10px;
}

.terms-section ul {
    list-style-type: decimal;
    padding-left: 20px;
}

.terms-section li {
    margin-bottom: 10px;
    font-size: 14px;
    color: #bbbbbb;
}