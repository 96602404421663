.footer {
  position: flex;
  /* width: 100%; */
  margin-left: 1.5%;
  background-color: #031116;
  padding: 40px 10% 0 12.5%;
  margin-top: 7.5%;
  margin-bottom: 2.5%;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 1000;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #525252;
  border-bottom: 1px solid #525252;
  flex-wrap: wrap;
  /* Allows columns to wrap on smaller screens */
}

.footer-statement {
  text-align: left;
  padding-top: 10px;
  color: #676b87;
  /* Slightly lighter text for readability */
  font-size: 14px;
  line-height: 1.5;
}

.footer-left {
  width: 30%;
}

.roulo-logo-footer {
  width: 280px;
  margin-left: -60px;
}

.brand-info h2 {
  color: #0fb8ec;
}

.contact-info {
  text-align: left;
  margin: 4.5% 0 0 0;
}

.contact-info a {
  color: #0fb8ec;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}

.contact-info a:hover {
  color: #1ec9ff;
}

.social-icons {
  margin-bottom: 20px;
}

.social-icons a {
  margin: 0 10px;
  color: white;
  font-size: 24px;
  transition: color 0.2s ease-in-out;
}

.legal-icons {
  display: flex;
  align-items: center;
}

.age-restriction,
.gc-icon {
  background-color: #222;
  padding: 8px 12px;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 18px;
}

.gc-icon {
  background-color: #f39c12;
}

#instagram:hover {
  color: #d62976;
}

#x-twitter:hover {
  color: black;
}

#facebook:hover {
  color: #007bff;
}

.footer-links {
  margin-top: 5px;
  width: 100%;
  text-align: left;
  list-style: none;
}

.footer-section {
  margin-top: 5px;
  width: 15%;
}

.footer-section h4 {
  margin-bottom: 10px;
  color: #0fb8ec;
  text-align: left;
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.footer-links li {
  margin-bottom: 6px;
}

.footer-links a {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  display: block;
  margin-left: 0;
  /* color: #6c757d; //this is for white background */
}

.footer-links a:hover {
  /* color: #007bff; */
  color: #0fb8ec;
}

.footer-copyright {
  /* color: #6c757d; //this is for white background */
  color: white;
  font-size: 14px;
  text-align: left;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  color: white;
  text-align: center;
  align-items: center;
  padding: 20px 0;
}

.dropdown-icon {
  display: none;
}

/*======================================================
                  Mobile Responsiveness
 ======================================================*/
@media (max-width: 1300px) {
  .footer {
    margin-left: auto;
    padding: 40px 10% 0 10%;
  }
}

@media (max-width: 768px) {
  .roulo-logo-footer {
    width: 280px;
    margin-left: -50px;
  }

  .footer {
    width: 100%;
    margin-left: 0%;
    padding: 40px 10% 0 12%;
  }

  .footer-container {
    flex-direction: column;
    /* align-items: center; */
    text-align: center;
    width: 100%;
  }

  .footer-section {
    width: 100%;
    background-color: #031b22e3;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    text-align: left;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    color: #a9a9b0;
    font-weight: bold;
    cursor: pointer;
    position: relative;
  }

  .footer-left,
  .footer-links,
  .footer-statement {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
  }

  .footer-links {
    display: none;
    color: #a9a9b0;
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  /* Show the dropdown content when active */
  .footer-section.active .footer-links {
    display: block;
  }

  .footer-links li {
    margin-bottom: 12px;
    /* padding-left: 10px; */
    /* Indent links slightly for visual hierarchy */
  }

  .footer-section.active .footer-links {
    display: block;
  }

  .footer-section h4 {
    font-size: 16px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-icon {
    display: flex;
    font-size: 14px;
    margin-left: 6px;
    /* transition: transform 0.3s ease; */
    color: #a9a9b0;
  }

  .dropdown-icon.rotate {
    transform: rotate(180deg);
  }
}