body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  /* color: white; */
  text-align: center;
  background-color: #031116;
  overflow-x: hidden;
  /* background-color: #031b22; */
}

.main {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  justify-content: left;
  align-items: center;
  z-index: 1;
  margin: 10% 2% -5%;
  /* Equal margins for top and bottom */
  /* margin-left: 5.75%; */
  padding: 24px 0 0 0;
  width: 100%;
  /* max-width: 1200px; */
}

.navButton {
  position: absolute;
  top: 25px;
  right: 20px;
}


/*======================================================
                          Nav
 ======================================================*/
h2 {
  text-align: left;
  margin-right: 70rem;
}

a {
  text-decoration: none;
}

.arrow-down {
  background: none;
  border: none;
  font-size: 24px;
  /* Adjust size as needed */
  cursor: pointer;
  text-align: left;
  margin-right: 88rem;
  position: absolute;
  right: 10px;
  /* Example positioning, adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.app-container {
  display: flex;
  /* min-height: 100vh; */
  /* transition: margin-left 0.3s ease; */
}

.game-slider-header {
  /* padding-top: 20px; */
  font-size: 20px;
  text-align: left;
  margin-bottom: -15px;
}

.slider-head-icon {
  color: #03c1fb;
}


/* adding styled for authentication buttons */

.auth-buttons {
  display: flex;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-image: linear-gradient(to right, #007BFF, #0056b3);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.button:hover,
.button:focus {
  background-image: linear-gradient(to right, #0056b3, #003885);
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
}

.button.register {
  background-image: linear-gradient(to right, #17a2b8, #148f97);
}

.button.register:hover,
.button.register:focus {
  background-image: linear-gradient(to right, #148f97, #0c6c77);
}


/*======================================================
                          Home Page
 ======================================================*/
#ballCanvas {
  display: block;
  margin: 0;
  background-color: #000000;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: -10px;
}

.hero-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #2b619eb9 1%, #021a22 35%, #021a22 65%, #2b619eb9 99%);
  height: 270px;
  width: 100%;
  max-width: 1200px;
  padding: 10px 30px;
  margin: 0px auto 20px auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #00c8ff;
  /* Solid neon blue border */
  box-shadow: 0 0 10px rgba(0, 200, 255, 0.6), 0 0 20px rgba(0, 200, 255, 0.4), 0 0 30px rgba(0, 200, 255, 0.2);
  color: white;
}

.hero-banner .hero-text {
  max-width: 100%;
  /* margin: 0; */
}

.hero-text {
  max-width: 60%;
  text-align: left;
  margin-top: -20px;
}

.hero-text h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.hero-text p {
  font-size: 1rem;
  color: #a0e9ff;
  margin-bottom: 1.5rem;
}

.hero-image {
  width: 35%;
  max-width: 375px;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  margin-top: -20px;
}

.hero-reg-btn {
  margin: auto !important;
}

@media (max-width: 1300px) {
  .main {
    margin: 10% 2% -5% 0%;
  }

  .hero-banner {
    margin-top: 100px;
  }
}

@media (max-width: 1150px) {
  .hero-banner {
    /* margin-top: 15%; */
    margin-top: 130px;
  }
}

@media (max-width: 938px) {
  .hero-banner {
    /* margin-top: 22%; */
    margin-top: 150px;
  }

  .hero-banner h1 {
    font-size: 28px;
  }

  .hero-banner p {
    font-size: 1rem;
  }

  .hero-text {
    width: 100%;
    /* padding-left: 50px; */
  }
}

@media (max-width: 768px) {
  .hero-banner {
    width: 90%;
    margin-left: 5%;
    margin-top: 200px;
  }

  .hero-banner h1 {
    font-size: 24px;
  }

  .hero-banner p {
    font-size: 16px;
    margin-left: 1%;
  }

  .hero-image {
    display: none;
  }
}

@media (max-width: 512px) {
  .hero-banner {
    margin-top: 200px;
    height: 200px;
    padding-bottom: 30px;
  }

  .hero-banner h1 {
    margin-top: 30px;
    font-size: 16px;
  }

  .hero-banner p {
    font-size: 12px;
  }

  /* .hero-reg-btn {
    height: 50px !important;
    margin-bottom: -50px !important;
  } */
}

@media (max-width: 400px) {
  .hero-banner h1 {
    font-size: 12px;
  }

  .hero-banner p {
    font-size: 9px;
  }
}

/* RouloOriginals.css */
.roulo-originals-container {
  padding: 20px;
  text-align: center;
  width: 105%;
}

.roulo-originals-title {
  font-size: 1.5rem;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.swiper-container {
  z-index: 2;
  /* width: calc(100% - 150px); */
  /* margin-left: 10.45%; */
  margin: -80px auto;
  /* margin-left: 160px; */
  width: 80%;
  /* overflow: hidden; */
  position: relative;
  /* grid-auto-columns: calc(12.5% - 8.75px); */
}

/* Custom CSS for Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show buttons on hover */
.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev {
  opacity: 1;
}

.sub-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sub-main-solos {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0;
  /* width: 102%; */
}

/* @media (max-width: 1500px) {
  .swiper-container {
    margin: -50px auto;
  }
} */

@media (max-width: 1360px) {
  .sub-main-solos {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 1200px) {
  .sub-main-solos {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 768px) {
  .lobby-container {
    margin-left: 10%;
  }

  .sub-main {
    width: 90%;
  }

  .sub-main-solos {
    grid-template-columns: repeat(4, 1fr);
    /* 4 cards per row on tablets */
  }
}

@media (max-width: 576px) {
  .sub-main-solos {
    grid-template-columns: repeat(3, 1fr);
  }

  .header-slots {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .main {
    margin: 2% 0;
    text-align: center;
    width: 100%;
  }

  .swiper-container {
    padding-left: 0;
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  /* .sidebar {
    display: none;
  } */
}

/*======================================================
                          Search Bar
 ======================================================*/
/* .search-container {
  background-color: #042731 !important;
  border-radius: 50px !important;
} */

/* Live feed box styles */
.livefeed {
  display: flex;
  flex-direction: column;
  background-color: #031116;
  border-radius: 10px;
  width: 80%;
  max-width: 1200px;
  margin-top: 100px;
}

.livefeed-row {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 2fr 2fr;
  background-color: #031116;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 8px;
}

.livefeed-row:nth-child(even) {
  background-color: #06313e;
}

.top-livefeed-row {
  background-color: #031116;
}

.livefeed-row-one {
  font-size: 15px;
  font-style: italic;
}

.livefeed-row div {
  display: flex;
  align-items: center;
  color: #fff;
  /* font-size: 16px; */
}

.livefeed-game-name {
  font-weight: bold;
}

.livefeed-bet-amount,
.livefeed-payout {
  justify-content: flex-end;
  position: relative;
  padding-right: 20px;
}

.livefeed-multiplier {
  justify-content: flex-end;
}

.livefeed-payout {
  color: #f39c12;
}

.livefeed-coin {
  margin-right: 6px;
  color: gold;
}

@media (max-width: 768px) {
  .livefeed {
    margin-bottom: -40px;
  }

  /* Adjust grid template to only display Game and Payout columns */
  .livefeed-row {
    grid-template-columns: 3fr 2fr;
    margin-left: 12px;
  }

  /* Hide columns for User, Bet Amount, and Multiplier on mobile */
  .livefeed-username,
  .livefeed-bet-amount,
  .livefeed-multiplier,
  .top-livefeed-row .livefeed-row-one:nth-child(2),
  .top-livefeed-row .livefeed-row-one:nth-child(3),
  .top-livefeed-row .livefeed-row-one:nth-child(4) {
    display: none;
  }

  .livefeed-row .livefeed-username,
  .livefeed-row .livefeed-bet-amount,
  .livefeed-row .livefeed-multiplier {
    display: none;
  }
}

/*======================================================
                          Sidebar Nav
 ======================================================*/
.sidenav-main-container {
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 60px;
  background-color: #031b22;
  /* background-color: #031116; */
  color: white;
  transition: width 0.3s ease;
  overflow: hidden;
  z-index: 1001;
  /* box-shadow: 50px 55px 5px -50px, -50px -50px 5px -50px rgba(218, 216, 216, 0.1);; */
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

.sidebar.open {
  width: 210px;
}

.main-content {
  flex: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

.sidebar-open .main-content {
  margin-left: 100px;
}

.sidebar-open .header {
  left: auto;
  transition: margin-left 0.3s ease;

}

/* .sidebar-open .rightNav {
  margin-right: 5%;
} */

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-bottom: 2.5px;
}

.sidebar-header h2 {
  margin: 0;
}

/* Popout submenu container */
.popout-menu {
  position: fixed;
  left: 57.5px;
  top: 160px;
  background-color: #031B22;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: none;
  z-index: 2000;
  overflow: visible;
  box-shadow: 0 4px 8px rgba(137, 137, 137, 0.2);
  /* Shadow for visibility */
}

/* Hover to show the popout menu */
.sidebar-item:hover .popout-menu {
  display: block;
}

/* Only show the popout when the sidebar is closed */
.sidebar:not(.open) .sidebar-item:hover .popout-menu {
  display: block;
}

.sidebar-item {
  padding: 1rem;
  text-align: left;
  position: relative;
  cursor: pointer;
  /* Required to position the submenu */
}

.sidebar-item:hover,
.sidebar-item.active {
  background-color: #08587249;
}

.submenu {
  padding-left: 20px;
  list-style-type: none;
  margin: 10px 0 0;
}

.submenu li {
  padding: 0.5rem 0;
}

.submenu li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submenu li a:hover {
  color: #1698c4;
}

/* Popout menu items */
.popout-menu a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  /* background-color: #031116; */
  background-color: #031B22;
  border-radius: 5px;
  margin: 8px;
}

.popout-menu a:hover {
  background-color: #1698c449;
}

.game-icons {
  margin-right: 6px;
}

#sidebar-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: 2px;
  margin-bottom: 26px;
  margin-top: 16px;
  transition: transform 0.3s ease;
}

#sidebar-btn.rotate {
  transform: rotate(270deg);
}

.sidebar-list {
  list-style: none;
  padding: 0;
}

.sidebar-items {
  background-color: #031116;
  box-sizing: border-box;
  border-radius: 20px;
  border: 6px solid #031B22;
}

.top {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.bottom {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.sidebar-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar-link span {
  margin-left: 1.5rem;
}

.sidebar-link i {
  min-width: 20px;
}

.slots-page {
  width: 100%;
  max-width: 1500px;
}

.forgot-pwd {
  cursor: pointer;
}

.reset-password-container {
  margin-top: 10%;
}