.sidenav-main-container {
    display: flex;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 60px;
    background-color: #03151B;
    color: white;
    transition: width 0.3s ease;
    overflow: hidden;
    z-index: 1001;
}

.sidebar.open {
    width: 180px;
}

.sidebar-header {
    background-color: #031b22;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    padding-bottom: 0px;
    height: 80px;
}

.sidebar-header h2 {
    margin: 0;
}

/* Popout submenu container */
.popout-menu {
    position: fixed;
    left: 57.5px;
    top: 160px;
    background-color: #031B22;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: none;
    z-index: 1001;
    overflow: visible;
    box-shadow: 0 4px 8px rgba(137, 137, 137, 0.2);
}

/* Hover to show the popout menu */
.sidebar-item:hover .popout-menu {
    display: block;
}

/* Only show the popout when the sidebar is closed */
.sidebar:not(.open) .sidebar-item:hover .popout-menu {
    display: block;
}

.sidebar-item {
    padding: 1rem;
    text-align: left;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: 10px;
    background-color: #031116;
    margin-bottom: 5px;
    /* gap: 4px; */
}

.sidebar-item:hover,
.sidebar-item.active {
    background-color: #00bfff6b;
    /* border: 5px; */
}

.submenu {
    /* padding-left: 1.5rem;
    list-style-type: none;
    margin-top: 0.5rem; */
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
}

.sidebar-item.active .submenu {
    height: auto;
    /* padding: 0.5rem 0;  */
}

.dropdown-arrow {
    margin-left: auto;
    transition: transform 0.3s ease;
}

.dropdown-arrow.rotate {
    transform: rotate(180deg);
}

.submenu li {
    padding: 0.5rem 0;
}

.submenu li a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.submenu li a:hover {
    color: #00bfff;
}

/* Popout menu items */
.popout-menu a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 0.5rem 1rem;
    /* background-color: #031116; */
    background-color: #031B22;
    border-radius: 5px;
    margin: 8px;
}

.popout-menu a:hover {
    background-color: #1698c449;
}

.game-icons {
    margin-right: 6px;
}

#sidebar-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 2px;
    margin-bottom: 26px;
    margin-top: 16px;
    transition: transform 0.3s ease;
}

#sidebar-btn.rotate {
    transform: rotate(-90deg);
}

.sidebar-list {
    list-style: none;
    padding: 0;
}

.sidebar-items {
    /* background-color: #031116; */
    background-color: #03151B;
    box-sizing: border-box;
    border-radius: 20px;
    border: 6px solid #03151B;
}

.sidebar-link {
    color: white;
    text-decoration: none;
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    /* text-align: center; */
}

.sidebar-link span {
    margin-left: 1.5rem;
}

.sidebar-link i {
    min-width: 20px;
}

.profile-item {
    display: none;
}

.profile-dropdown-menu {
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #031116;
    border: 1px solid #444;
    border-radius: 8px;
    width: 180px;
    padding: 10px 0;
    z-index: 2001 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-dropdown-menu li {
    padding: 10px 15px;
}

.profile-dropdown-menu li a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease;
}

.profile-dropdown-menu li a:hover {
    background-color: #00bfff6b;
    border-radius: 5px;
}

.profile-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
}

.content {
    margin-left: 80px;
    padding: 2rem;
    width: calc(100% - 80px);
    transition: margin-left 0.3s ease, width 0.3s ease;
}

.sidebar-open .content {
    margin-left: 250px;
    width: calc(100% - 250px);
}

/* Mobile Sidebar */
@media (max-width: 1300px) {

    /* need to fix bottom margin/padding for this view */
    .sidebar {
        display: flex;
        position: fixed;
        top: 80px;
        width: 100%;
        height: 70px;
        justify-content: space-around;
        align-items: center;
        background-color: #03151B;
        z-index: 1000;
        box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.10);
        overflow: visible;
    }

    .sidebar-items {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style: none;
        gap: 10%;
        /* border-radius: 0px; */
        height: 70px;
    }

    .sidebar-link {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 4px;
    }

    .sidebar-link span {
        margin-left: 0;
    }

    .profile-item {
        display: flex;
    }

    /* Hide content behind sidebar on mobile */
    .content {
        margin-bottom: 60px;
    }

    /* Sidebar item adjustments for bottom bar */
    .sidebar-item {
        flex: 1;
        text-align: center;
        /* margin: 0 0 20px 20px; */
        background-color: #03151B;
    }

    .sidebar-item:hover {
        background-color: #03151B;
    }

    /* Icon and text styling for bottom bar */
    .sidebar-item i {
        font-size: 20px;
        display: block;
    }

    .sidebar-item span {
        display: block;
        font-size: 16px;
        margin-top: 4px;
    }

    /* Remove the popout menu for mobile */
    .popout-menu {
        display: none;
    }

    /* Hide header and extra padding/margins */
    .sidebar-header,
    #sidebar-btn {
        display: none;
    }

    .dropdown-arrow {
        display: none;
    }
}

@media (max-width: 768px) {
    .sidebar-items {
        gap: 5%;
    }
}

@media (max-width: 600px) {
    .sidebar-items {
        gap: 0;
    }
}