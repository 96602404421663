/* ResponsibleGambling.jsx styles */
.legal-container {
    max-width: 800px;
    margin-top: -50px;
}

.legal-header {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.legal-header h1 {
    font-size: 22px;
    color: #ffffff;
    margin: 0;
    font-weight: 100;
}

.legal-header p {
    font-size: 14px;
    color: #c9c9c9;
}

.legal-content {
    text-align: left;
}

.legal-content strong {
    /* color: #e6e6e6; */
    color: #999999;
    font-weight: 100;
}

.legal-content h2,
.legal-content h3 {
    color: #ffffff;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 100;
}

.legal-content p {
    font-size: 14px;
    color: #999999;
    line-height: 1.6;
}

.legal-content ul {
    list-style: disc;
    margin-left: 20px;
    color: #c9c9c9;
    font-size: 14px;
}

.legal-content a {
    color: #007bff;
    text-decoration: none;
}

.legal-content a:hover {
    text-decoration: underline;
}

.legal-subheader {
    min-width: 400px;
    margin-bottom: 16px;
}

.legal-list {
    margin-left: 2rem;
    list-style: none;
}

.legal-list>li {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1rem;
    color: #999999;
    font-size: 14px;

}

.legal-list>li::before {
    content: "○";
    color: #00bfff;
    margin-right: 0.5rem;
}

.legal-list ol {
    margin-top: 0.5rem;
    margin-left: 2.5rem;
}

.legal-list ol>li {
    list-style-type: decimal;
    margin-bottom: 0.5rem;
    color: #cccccc;
    font-size: 0.9rem;
}