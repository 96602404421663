.big-wins-container {
    width: 80%;
    max-width: 1200px;
    min-height: 320px;
    background: #031B22;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.game-image {
    width: 150px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    z-index: 1000;
}

.game-header {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.game-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.game-details {
    text-align: left;
    white-space: nowrap;
    border-top: rgba(90, 90, 90, 0.452) 2px solid;
}

.game-title {
    font-size: 24px;
    color: white;
    margin: 0;
    padding-left: 5px;
}

.game-bets {
    color: #bbb;
    font-size: 14px;
    margin: 5px 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.39);
    border-radius: 8px;
    /* width: 20%; */
}

.play-game-btn {
    display: block;
    background: #4CAF50;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 15px 0;
}

.play-game-btn:hover {
    background-color: #45a049;
}

.big-wins-title {
    font-size: 20px;
    color: white;
    margin: 10px 20px;
    text-align: center;
}

.big-wins-list {
    /* background-color: #0a2a35; */
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
}

/* .win-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #1b3b4f;
    color: #ccc;
} */

.win-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    padding: 10px;
    background-color: #03313d;
    color: #ffffff;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
    border-bottom: 2px solid #04515d;
    text-align: center;
}

.win-item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    /* display: flex;
    justify-content: space-between; */
    padding: 10px;
    color: #bbb;
    border-bottom: 1px solid #03313d;
}

.win-item:last-child {
    border-bottom: none;
}

.win-item span {
    text-align: center;
}

.tabs {
    display: flex;
    justify-content: flex-start;
    /* margin: 20px 0; */
    gap: 10px;
    background-color: #03313d;
    padding: 10px;
    border-radius: 8px;
}

.tab-btn {
    background: transparent;
    border: 2px solid #04515d;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s, color 0.3s;
}

.tab-btn:hover {
    background: #043f4e;
    color: #ffffff;
}

.tab-btn.active {
    background: #1d9bf0;
    color: white;
    border-color: #1d9bf0;
    box-shadow: 0 4px 8px rgba(29, 155, 240, 0.4);
}

.tab-content {
    /* background: #041f25; */
    width: 100%;
    padding-left: 175px;
    margin-top: -200px;
}

.game-description {
    text-align: left;
}
