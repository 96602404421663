/* Keno.css */
.keno-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px auto;
    /* Center horizontally */
    height: 80vh;
    /* Adjust height as needed */
    max-width: 1200px;
    /* Set max-width to center the content */
    box-sizing: border-box;
    margin-top: 120px;
}

.keno-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    /* margin-top: 20px; */
    padding: 10px;
}

.keno-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    width: 800px;
    /* Increase width to make buttons larger */
}

.keno-number {
    width: 100%;
    height: 80px;
    background-color: #073A49;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 10;
}

.keno-number span {
    position: absolute;
    /* Place the text over the gem */
    z-index: 2;
    /* Ensure the text is above the gem */
}

.keno-number:hover:not(.selected):not(.disabled) {
    background-color: #0b556b;
    /* Slightly grey */
    transform: scale(1.1);
    /* Pop out effect */
}

.keno-number.selected {
    background-color: #0aa9b4;
}

.keno-number.result {
    /* background-color: #ff0; */
    background-color: #006400;
    /* color: rgb(0, 0, 0); */
}

.keno-number.disabled {
    background-color: #2b4c56;
    color: rgb(133, 133, 133);
    cursor: not-allowed;
}

.control-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.control-panel label {
    color: #fff;
    margin: 5px 0;
}

.control-panel input,
.control-panel select {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: none;
}

.auto-pick,
.clear-table {
    flex: 10;
    padding: 10px 20px;
    margin: 8px 0;
    margin-left: 5px;
    /* margin-right: -5px; */
    background-color: #00EEFF;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.keno-instructions,
.keno-probabilities {
    width: 800px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.322);
    /* background-color: #073A49; */
    color: #fff;
    text-align: center;
    margin-top: 20px;
    border-radius: 5px;
    box-sizing: border-box;
}

.keno-instructions p,
.keno-probabilities h3 {
    margin: 0;
}

.keno-probabilities h3 {
    margin-bottom: 10px;
}

.keno-results {
    padding: 10px 20px;
    background-color: #031B22;
    /* Dark background for contrast */
    color: #00FF00;
    /* Green for winnings */
    font-size: 1.2rem;
    /* Slightly larger text */
    border: 2px solid #00FF00;
    /* Green border */
    border-radius: 10px;
    /* Rounded corners */
    text-align: center;
    width: 80%;
    /* Center it under the grid */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    /* Add a subtle shadow */
}

.keno-results h3 {
    margin: 0;
    /* font-weight: bold; */
    text-transform: uppercase;
}

.probabilities-container {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 5px;
    margin-top: 10px;
    min-width: 100%;
}

.probability-box {
    background-color: #073A49;
    color: #fff;
    padding: 6px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
    /* min-width: 100%; */
    text-align: center;
    cursor: default;
}

.keno-gem-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

/* .keno-number.winning {
    background-color: #006400;
    color: #ffffff;
} */

/* .keno-number.losing {
    background-color: #2c2c2c;
    color: #ff0000;
} */

.keno-number.mismatch {
    background-color: #2c2c2c;
    /* Darker background */
    color: #ff0000;
    /* Red text */
}

.side-panel #keno-button {
    width: 120%;
    padding: 8px;
    background-color: #5d5b5b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.side-panel #keno-button:hover {
    background-color: #a2a0a0;
    transition: 0.5s;
}

.autoplay-progress-container {
    width: 80%;
    height: 20px;
    background-color: #031B22;
    /* Dark background for the bar */
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
}

.autoplay-progress-bar {
    height: 100%;
    background-color: #00FF00;
    width: 0%;
    transition: width 0.3s ease-in-out;
}

.keno-autoplay-progress-bar {
    color: black;
}

.keno-number:hover:not(.selected):not(.disabled):not(.playing) {
    background-color: #0b556b;
    transform: scale(1.1);
}

.disabled-hover {
    pointer-events: none;
    cursor: default;
    /* opacity: 0.7; */
}

.keno-hits-container {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 5px;
    margin-top: 10px;
    width: 100%;
}

.keno-hit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.322);
    padding: 5px;
    border-radius: 5px;
}

.keno-hit-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* font-size: 10px; */
    width: 100%;
}

.keno-hit-label {
    font-size: 14px;
    color: #fff;
}

.keno-hit-indicator {
    width: 20px;
    height: 10px;
    background-color: rgba(100, 100, 100, 0.7);
    border-radius: 3px;
    margin: 4px 0;
}

.keno-hit-indicator-active {
    background-color: #00ff00;
}

/* .keno-win-fadein {
    opacity: 0;
    transform: scale(0.8);
    animation: fadeInScale 0.3s ease-out forwards;
}

.keno-win-fadeout {
    opacity: 1;
    animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
} */

/* .keno-hit-counter {
    text-align: center;
    font-size: 18px;
    color: #00ff00;
    margin-top: 10px;
    margin-bottom: -10px;
} */

@media (max-width: 768px) {
    .keno-grid-container {
        width: 100%;
    }

    .keno-grid {
        width: 100%;
    }

    .keno-number {
        height: 60px;
    }

    .keno-instructions,
    .keno-probabilities {
        width: 100%;
    }

    .probabilities-container {
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    }

    .probability-box {
        padding: 8px;
        font-size: 12px;
    }

    #keno-auto-btns {
        width: 98.5% !important;
    }
}

/* .keno-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 20px;
    background-color: #333;
    border-radius: 10px;
    height: 550px;
    box-sizing: border-box;
    margin-right: 40px;
}

.game-mode {
    display: flex;
    margin-bottom: 10px;
}

.game-mode button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.game-mode button.active {
    background-color: #555;
} */

/* .tile-counter-container {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    margin-top: 10px;
}

.tile-counter {
    background-color: #073A49;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    min-width: 120px;
    text-align: center;
    cursor: default;
    width: 10px;
} */