/* .content {
  color: black;
  text-align: center;
} */

/* .content {
  margin-left: 80px;
  width: calc(100% - 80px);
  transition: margin-left 0.3s ease, width 0.3s ease;
} */

.sidebar-open .content {
  margin-left: 250px;
  width: calc(100% - 250px);
}


body {
  box-sizing: border-box;
  background-color: #1a1a1a;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: white;
}

.main-navigation {
  background-color: #222;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.main-navigation button {
  background: none;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.game-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.game-section article {
  background-color: #444;
  color: white;
  padding: 20px;
  margin: 10px;
  width: calc(33.333% - 20px);
  text-align: center;
}

/*======================================================
                            Navbar
  =====================================================*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


/*======================================================
                            Sign In / Sign Up
  =====================================================*/

.signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 150px; */
  /* height: 100vh; */
}

.signup-container {
  /* width: 100%; */
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  padding: 20px;
}

.signin-content {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: white;
  /* background-color: rgb(72, 72, 72); */
  /* background-color: #084F64; */
  background: linear-gradient(180deg, #0c6884, #021a22);
}

.signup-content {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  /* max-height: 700px; */
  background: linear-gradient(180deg, #0c6884, #021a22);
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  /* margin: 10px 0; */
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.5;
  margin-left: -105px;
  /* margin-bottom: -40px; */
}

.checkbox-label input[type="checkbox"] {
  margin-right: 10px;
  width: 16px; /* Standard size for the checkbox */
  height: 16px; /* Standard size for the checkbox */
  cursor: pointer;
}

.checkbox-container a {
  text-decoration: none;
}

.checkbox-container a:hover {
  text-decoration: underline;
}

.checkbox-container a:visited {
  color: #4f9df9; /* Same color as the unvisited link */
}

.register-checkbox-span {
  margin-left: -100px;
  font-size: 12px;
}

.signin-form .form-group {
  margin-bottom: 20px;
  /* margin-bottom: 10px; */
}

.signin-form {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.signup-form {
  display: flex;
  justify-content: column;
  gap: 20px;
  width: 100%;
}

.signin-form label {
  display: block;
  margin-bottom: 5px;
}

.signup-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signin-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signin-form button {
  margin-top: 20px;
}

.signin-button {
  width: 100%;
  padding: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signin-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

/* this might be the issue ?????? */
.usernameNote {
  font-size: 75%;
  padding-top: 0px;
}

.signup-head {
  text-align: center;
  /* padding-bottom: 12px; */
  /* margin-bottom: 20px; */
}

.already-have-acct {
  text-align: center;
  padding-bottom: 12px;
  margin-top: 2rem;
}

.dont-have-acct {
  text-align: center;
  padding-bottom: 12px;
  margin-top: 0.5rem;
}

.signup-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  margin: auto;
  padding-top: 2.5rem;
}

.sign-up-in-link {
  color: rgb(0, 183, 255);
  text-decoration: none;
}

.form-label {
  /* text-align: left; */
  display: block;
  margin-bottom: 5px;
  margin-top: 12px;
}

.form-input {
  width: 100%;
}

.form-column {
  display: flex;
  flex-direction: column;
  /* width: 75%; */
}

.react-datepicker-wrapper {
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 16px;
}

select.form-control {
  background-position: right 10px center;
  background-repeat: no-repeat;
  width: 100%;
  /* Make the width consistent with other inputs */
  border-radius: 4px;
  /* Rounded corners */
  box-shadow: none;
  /* Remove any additional box shadow */
  transition: border-color 0.3s ease;
  /* Smooth transition for border color change */
}

select.form-control:hover {
  border-color: #007bff;
}

select.form-control:focus {
  outline: none;
  /* Remove default outline */
  border-color: #1E90FF;
  /* Change border color on focus */
  box-shadow: 0 0 5px rgba(30, 144, 255, 0.5);
  /* Add blue glow shadow */
}

@media (min-width: 768px) {
  .signup-form {
    flex-direction: row;
    justify-content: space-between;
  }

  /* .form-column {
    width: 48%;
  } */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popup-content h2 {
  margin: 0;
}

.popup-content p {
  margin: 10px 0;
}

.popup-content button {
  padding: 10px 20px;
  border: none;
  background: green;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .signup-container {
    /* margin-top: 30%;
    margin-bottom: 20%; */
    margin: auto;
  }

  .signup-form {
    flex-direction: column;
  }

  .signup-head {
    margin-bottom: 0px;
  }

  .signup-btn {
    margin-top: -30px;
  }

  .already-have-acct {
    margin-bottom: -20px;
  }

  .form-column {
    width: 100%;
    margin-bottom: -10px;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .signin-content {
    padding: 15px;
    margin: 20px;
  }
}

@media (max-width: 480px) {
  .signin-content {
    padding: 10px;
    margin: 10px;
  }

  .signin-form input,
  .signin-button {
    padding: 8px;
  }
}

/*======================================================
                    Registration Form
  =====================================================*/
.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

/* input[type="text"],
  input[type="password"],
  button,
  textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
  } */

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
  text-align: left;
}

.instructions>svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.dob-note {
  font-size: 12px;
  text-align: left;
  padding-top: 8px;
}

.password-input-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  color: black;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  /* margin-top: 10px ; */
}

.resetpw-eye-icon {
  position: absolute;
  right: 10px;
  top: 55px;
  color: black;
  cursor: pointer;
  /* font-size: 1.1rem; */
}

.input-wrapper {
  position: relative;
}

/*======================================================
                    Swiper Styles
  =====================================================*/
.swiper-button-next {
  margin-top: -350px;
}

.swiper-container-slots {
  z-index: 2;
  margin-top: -5%;
  margin-left: 10%;
  width: 80%;
  overflow: hidden;
  position: relative;
}

/* Custom CSS for Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show buttons on hover */
.swiper-container-slots:hover .swiper-button-next,
.swiper-container-slots:hover .swiper-button-prev {
  opacity: 1;
}

@media (max-width: 1300px) {
  .swiper-container-slots {
    margin-top: 1%;
  }
}

@media (max-width: 768px) {
  .main {
    text-align: left;
  }

  .swiper-container-slots {
    padding-left: 0;
    text-align: center;
    width: 100%;
    margin-left: 5%;
    margin-top: 17.5%;
  }
}

/* Search css */
/* Container for the search bar */
.header-slots {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: -10px;
}

/* Ensure the heading and search container are side by side */
.header-slots h1 {
  margin-right: 20px;
  white-space: nowrap;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #042731;
  border-radius: 50px;
  padding: 5px 10px;
  width: 100%;
}

/* Style for the search icon */
.search-container .icon {
  color: white;
  /* Color for the icon */
  margin-right: 10px;
  /* Space between icon and input */
  font-size: 18px;
  /* Size of the icon */
}

/* Style for the search input */
.search-container input {
  background: none;
  /* No background color */
  border: none;
  /* No border */
  color: white;
  /* White text color */
  font-size: 16px;
  /* Font size */
  outline: none;
  /* No outline */
  flex-grow: 1;
  /* Take up remaining space */
}

.carousel-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  /* padding: 10px; */
  padding-top: 20px;
  position: relative;
}

.carousel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.carousel-controls {
  display: flex;
  gap: 6px;
  position: absolute;
  top: 18px;
  right: 0%;
}

.carousel-arrow {
  background-color: rgba(255, 255, 255, 0.1);
  color: #00eeff;
  border: none;
  border-radius: 25%;
  width: 35px;
  height: 35px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-arrow:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0.5;
  cursor: unset;
}

.carousel-arrow:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.3);
}

.carousel-track-wrapper {
  overflow: hidden;
  display: flex;
  justify-content: start;
}

.carousel-track {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 8px;
  /* width: calc(300px * 7 + 20px * 6);  */
  scroll-behavior: smooth;
  padding: 10px 0;
}

.carousel-track>div {
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 1366px) {
  .carousel-track {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 1021px) {
  .carousel-track {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 854px) {
  .carousel-track {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .carousel-container {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .carousel-track {
    grid-template-columns: repeat(3, 1fr);
  }
}