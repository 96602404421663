.slot-game-container {
    position: relative;
    width: 80%;
    max-width: 1200px;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: #031B22;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: height 0.3s ease, width 0.3s ease;
    display: flex;
    flex-direction: column;
    /* Default stacking */
    /* box-sizing: border-box; */
}

#slot-game-container {
    margin-top: -80px;
}

.slot-game-container.theater-mode {
    max-width: 95%;
    /* height: 800px; */
    margin: 0 auto;
    border-radius: 15px;
}

.slot-game-iframe {
    width: 100%;
    height: 675px;
    border: none;
}

.slot-game-container,
.slot-game-iframe {
    box-sizing: border-box;
}

.slot-game-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.game-options {
    position: absolute;
    /* top: 10px; */
    right: 10px;
    display: flex;
    gap: 10px;
    z-index: 10;
    padding-right: 20px;
    /* margin-right: 2.5%; */
}

.game-option-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    color: white;
}

.game-option-btn:hover .tooltip {
    display: block;
}

.tooltip {
    display: none;
    position: absolute;
    top: -30px;
    right: -20px;
    background: #111111;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
}

.icon {
    width: 24px;
    height: 24px;
}

.slot-game-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 20px;
    z-index: 10;
}

.slot-game-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.slot-register-button {
    background-color: #4caf50;
    color: white;
}

.slot-register-button:hover {
    background-color: #45a049;
}

.demo-button {
    background-color: #1f2937;
    color: white;
}

.demo-button:hover {
    background-color: #2c3a50;
}

.slot-game-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: white;
    font-size: 14px;
}

.footer-logo {
    font-size: 16px;
    font-weight: bold;
}

.footer-game-info {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    font-size: 14px;
}

#slot-game-iframe:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

#slot-game-iframe:-moz-full-screen {
    width: 100%;
    height: 100%;
}

#slot-game-iframe:-ms-fullscreen {
    width: 100%;
    height: 100%;
}

#slot-game-iframe:fullscreen {
    width: 100%;
    height: 100%;
}

.slots-swiper-container {
    width: 80%;
    max-width: 1200px;
    margin-bottom: -100px;
}

@media (max-width: 1300px) {
    #slot-game-container {
        margin-top: 10px;
    }
}

@media (max-width: 1000px) {
    #slot-game-container {
        margin-top: 35px;
    }
}

@media (max-width: 768px) {
    #slot-game-container {
        margin-top: 100px;
    }
}

@media (max-width: 415px) {
    #slot-game-container {
        margin-top: 110px;
    }
}

.slot-game-iframe {
    width: 100%;
    height: 675px;
    border: none;
}

/* For screens 1100px and smaller */
@media (max-width: 1100px) {
    /* .slot-game-iframe {
        display: none;  
    } */

    .game-prompt {
        display: block;
    }

    .play-game-btn {
        display: none;
    }
}

.slots-loading-overlay {
    background-color: rgba(0, 0, 0, 0.342);
    /* background-color: rgba(163, 12, 12, 0.342); */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* width: 500px;
    margin: auto; */
    /* max-width: 1200px !important; */
}

.slots-loading-gif {
    width: 100%;
    max-width: 375px;
    height: 100%;
    max-height: 375px;
    /* background-color: rgba(0, 0, 0, 0.342); */
}

.demo-toggle-btn {
    background-color: #2c3a50;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.demo-toggle-btn:hover {
    background-color: #1f2937;
}