.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
}

.modal-content {
    /* background: linear-gradient(180deg, #0c6884, #021a22); */
    background-color: #021a22;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 450px;
}

.model-head {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab-button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.tab-button:hover,
.active-tab {
    background-color: #0c6884;
    border-radius: 10px;
}

.crypto-selection {
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 20px; */
}

.crypto-selection button {
    background-color: #0f3746;
    color: white;
    border: none;
    border-radius: 10px;
    width: 100px;
    height: 100px;
    margin: 0 10px;
    cursor: pointer;
}

.crypto-selection button:hover {
    background-color: #205c6e;
}

.crypto-selection button.active {
    border-color: #007bff;
    background-color: #205c6e;
    color: white;
}

.withdrawal-section {
    text-align: left;
    padding: 20px;
}

.withdrawal-field {
    margin-bottom: 10px;
}

.withdrawal-field label {
    display: block;
    margin-bottom: 5px;
    color: white;
}

.withdrawal-field input {
    width: 100%;
    padding: 8px;
    background-color: #1F2A36;
    border: 1px solid #2E3B4E;
    border-radius: 5px;
    color: white;
}

.withdrawal-field input::placeholder {
    color: white;
}

.transaction-fee {
    color: #aaa;
    text-align: center;
    font-size: 14px;
    padding-top: 10px;
}

.withdraw-btn {
    background-color: #020d11;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
    margin-top: 10px;
    margin-left: 20%;
    margin-right: 5%;
}

#deposit-btn {
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
}

.proceed-btn {
    background-color: #020d11;
    color: white;
    border: none;
    border-radius: 5px;
}

.proceed-btn:hover, .withdraw-btn:hover {
    background-color: #205c6e;
}

.payment-text {
    color: black;
}

.payment-label {
    margin-bottom: 20px;
    color: black;
    text-align: center;
}

#fun-deposit {
    color: white;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
}

#payment-address {
    color: white;
    margin-bottom: 20px;
}

.coin-icon {
    font-size: 24px;
    margin-bottom: 5px;
}

.crypto-img {
    height: 40%;
    border-radius: 50px;
}

.crypto-dropdown {
    width: 100%;
    padding: 10px;
    background-color: #1F2A36;
    color: white;
    border: 1px solid #2E3B4E;
    border-radius: 5px;
}

.crypto-dropdown option {
    background-color: #1F2A36;
    color: white;
}

.conversion-rate-note {
    font-size: 10px;
}