.header {
    position: fixed;
    top: 0;
    /* left: 0; */
    height: 80px;
    width: 100%;
    /* width: calc(100% - 250px); Deduct the sidenav width from total width */
    padding: 10px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(218, 216, 216, 0.021);
    background-color: #031b22;
    /* transition: margin-left 0.3s ease; */
}

/* .navbar-header-container {
    max-width: 1200px;
} */

.logo {
    display: flex;
    align-items: center;
    /* position: sticky; */
    margin-left: 3%;
    /* margin-left: 320px; */
}

.roulo-logo {
    width: 150px;
    margin-left: -27.5%;
    height: auto;
    max-height: 100px;
    object-fit: contain !important;
    /* z-index: 2000; */
}

.deposit-container {
    padding: 10px;
    border-radius: 20px;
    gap: 10px;
}

.deposit {
    /* might need to add back '.user-balance' and change other variable */
    background: linear-gradient(0deg, #237e29, #1c271c);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    transition: transform 0.2s, ease 0.2s;
    box-shadow:
        0 0 2px 1px #1bb123,
        0 0 1px 1px #1bb123;
    /* Extended neon effect */
}

.user-balance {
    padding-right: 10px;
}

.deposit:hover {
    background-color: #0eabdb;
}

/* .navbar {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-right: auto;
} */

.middleNav-container {
    display: flex;
}

.navbar-username {
    font-size: 22px;
    padding: 0px 6px;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
}

.user-dropdown-menu {
    max-height: 0;
    /* Initially hidden */
    opacity: 0;
    overflow: hidden;
    background-color: #062a35;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    padding: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
    position: absolute;
    top: 110%;
    right: 10px;
    width: 200px;
    z-index: 1000;
}

.user-dropdown-menu.open {
    max-height: 200px;
    /* Adjust for content height */
    opacity: 1;
    padding: 10px;
}

.user-dropdown-item {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
    border-radius: 10px;
    transition: background-color 0.2s ease;
}

.user-dropdown-item:hover {
    background-color: #031b22;
    border-radius: 10px;
    margin-left: auto;
}

.fas.fa-caret-down {
    margin-left: auto;
}

.fas.fa-caret-down.open {
    transform: rotate(180deg);
}

.user-icon {
    margin-right: 6px;
}

/* .navbar a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 15px;
    color: #828282;
    font-weight: 300;
    margin-left: 40px;
}

.navbar a::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 2px;
    background: #828282;
    transition: .3s;
}

.navbar a:hover::before {
    width: 100%;
} */

.rightNav {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    margin-right: -2.5%;
    transition: margin-right 0.3s ease;
}

/* Adjust when sidebar is open */
body.sidebar-open .rightNav {
    margin-right: 2.5%; /* Adjust this value as needed */
}

.middleNav {
    display: inline-flex;
    /* display: relative; */
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: sticky;
    /* margin-right: 120%;  */
}

.navbar-loading-gif {
    display: block;
    width: 100%;
    max-width: 375px;
    height: 100%;
    max-height: 375px;
}

.btn-reg {
    /* background: linear-gradient(45deg, #031B22, #09566d); */
    background-color: #1888ff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
    margin-left: 10px;
    margin-right: 50px;
}

.btn-reg:hover,
.rightNav .btn-reg:focus {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.btn-username {
    padding: 5px 10px 5px 12px;
    background: linear-gradient(45deg, #031B22, #09566d);
}

.btn-username:hover {
    transform: none;
}

.btn-in {
    display: inline-flex;
    /* Use flexbox to layout contents */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    background: transparent;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    white-space: nowrap;
    /* Prevent wrapping */
}

.btn-in:hover,
.rightNav .btn-in:focus {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Hamburger menu */
.menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.account-dropdown {
    position: relative;
}

.account-dropdown-btn {
    background-color: #2c3a2d23;
    box-shadow:
        /* 0 0 2px 1px #1bb123,  */
        0 0 1px 1px rgba(107, 107, 107, 0.185);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.account-dropdown-btn i,
.navbar-username i {
    /* margin-left: 5px !important; */
    font-size: 15px !important;
    transition: transform 0.3s ease;
}

.account-dropdown-btn .open {
    transform: rotate(180deg);
}

.account-dropdown-menu {
    max-height: 0;
    /* Start hidden */
    opacity: 0;
    overflow: hidden;
    background-color: #062a35;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    padding: 10px;
    transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
    position: absolute;
    top: 110%;
    left: 0;
    width: 250px;
    z-index: 1000;
}

.account-dropdown-menu.open,
.user-dropdown-menu.open {
    max-height: 300px;
    /* Adjust based on the dropdown's full height */
    opacity: 1;
    padding: 10px;
    /* Padding to appear when open */
}

.account-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-top: 12px;
    background-color: #083341 !important;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 13px;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s;
}

.account-dropdown-item:hover {
    border-radius: 10px;
    background-color: #031b22 !important;
    box-shadow: 0 0 1px 1px rgba(82, 82, 82, 0.555);
}

.account-dropdown-content {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.account-dropdown-divider {
    height: 2px;
    background-color: #00000052;
    margin: 10px 0;
}

.currency-notice {
    padding-bottom: 10px;
    font-size: 14px;
}

.account-balance-title {
    color: #ccc;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}

.account-balance-amount {
    font-size: 16px;
    font-weight: 500;
}

.account-balance-circle-indicator {
    width: 15px;
    height: 15px;
    border: 2px solid #ccc;
    border-radius: 50%;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.account-balance-circle-indicator.selected {
    background-color: #1bb123;
    border-color: #1bb123;
}

.promo-balance-title-container {
    display: flex;
    align-items: center;
    gap: 8px;
    /* Space between the title and icon */
}

.promo-balance-icon {
    font-size: 14px;
    margin-bottom: 8px;
    /* color: #ebebeb; */
    color: #2BFEDC;
    /* margin-left: 0px; */
}

.navbar-promo-balance-icon {
    margin-left: 10px;
    margin-bottom: 1px;
}

.primary-balance-icon {
    width: 30px; /* Adjust the width of the icon */
    height: 30px; /* Adjust the height of the icon */
    margin-top: -4px;
    margin-left: -8px;
}

.navbar-primary-balance-icon {
    margin-bottom: -10px;
    margin-left: 4px;
    margin-right: -8px;
}

/* Mobile-only styling */
@media (max-width: 1300px) {
    .header {
        left: 0;
    }

    .rightNav {
        margin-right: 6%;
    }

    .logo {
        padding-left: 0px;
        margin-left: 110px;
    }

    .navbar-profile {
        display: none;
    }

    .signin-reg-container {
        padding-right: 0px;
        margin-right: -40px;
    }
}

@media (max-width: 768px) {

    .nav-icons,
    .nav-actions {
        flex-direction: row;
        gap: 15px;
    }

    .navbar-container {
        flex-direction: row;
    }

    .logo {
        font-size: 18px;
        margin-left: 100px;
    }

    .header {
        height: 80px;
        width: 110%;
        margin-left: -40px;
    }

    /* .btn-username,
    .navbar-username {
        margin-right: 40%;
    } */
}

@media (max-width: 618px) {
    .rightNav {
        margin-left: -15%;
    }
}

@media (max-width: 600px) {
    .logo {
        margin-left: 10%;
    }
}

@media (max-width: 512px) {
    .rightNav {
        margin-left: -40%;
    }

    .logo {
        padding-left: 0px;
    }
}

@media (max-width: 412px) {
    .rightNav {
        margin-right: 0;
    }

    .logo {
        width: 100px;
        margin-left: 10%;

    }
}