.model-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
}

.model-content {
    background-color: #031B22;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    width: 100%;
    max-width: 300px;
    color: white;
}

.profile-header {
    display: flex;
    justify-content: space-between;
    margin-left: 30%;
    align-items: center;
    margin-bottom: 20px;
}

.profile-avatar {
    display: flex;
    align-items: center;
}

.avatar-icon {
    font-size: 40px;
}

.profile-rank,
.profile-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.rank-section {
    background-color: #0f7692d3;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 200px;
    text-align: center;
}

.rank-section p {
    margin: 0;
}

.progress-bar {
    background-color: rgb(22, 22, 22);
    border-radius: 10px;
    height: 10px;
    width: 100%;
    margin: 10px 0;
}

.progress {
    background-color: #0fb8ec;
    height: 100%;
    border-radius: 10px;
    width: 80%;
}

.stat-box {
    background-color: #064152;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 200px;
    text-align: center;
}

.close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}