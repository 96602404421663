.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  /* Ensure it appears above other elements */
}

.loading-overlay-gif {
  /* width: 100px; */
  /* Adjust the size as needed */
  height: auto;
}