/* styling for the Game controler */

.game-container {
    /* color: #031116; */
    display: flex;
    /* height: 650px; */
    /* height: auto !important; */
    /* height: 75vh; */
    width: 100%;
    max-width: 1202px;
    border-radius: 12px;
    /* margin-right: 12rem; */
    margin-bottom: 96px;
    /* margin-left: 12rem; */
    background-color: #073A49 !important;
    /* background-color: white !important; */
}

.game-container-body {
    display: flex;
    flex: 1;
}

.side-panel {
    border-radius: 10px 0 0 10px;
    flex: 0 0 auto;
    box-sizing: border-box;
    text-align: left;
    padding: 10px;
    padding-right: 60px;
    width: 30%;
    /* height: 60%; */
    background-color: #073a49;
}

.game-panel {
    border-radius: 0 10px 10px 0 !important;
    flex: 1 1 auto;
    box-sizing: border-box;
    background-color: #031B22 !important;
    display: flex;
    height: 70% !important;
    width: 830px !important;
    margin-left: -10px;
    /* margin-top: 5%; */
}

.game-container-footer {
    background-color: #031B22;
    display: flex;
    /* flex: 0 0 auto; */
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 10px 20px;
    position: absolute;
    bottom: 44px;
    border-top: solid #073A49;
    margin-top: -100px !important;
    width: 100%;
    max-width: 1202px;
}

.game-container-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.game-container-footer-left,
.game-container-footer-right {
    display: flex;
    gap: 10px;
}

.game-container-footer-center {
    font-size: 0.9rem;
    color: #bbb;
    text-align: center;
    flex-grow: 1;
}

.game-container-footer-button {
    background-color: #073A49;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.85rem;
}

.game-container-footer-button:hover {
    background-color: #0c5970;
}

/* .game-container-footer-button:focus {
    outline: 2px solid #ffffff;
} */

.side-panel h2 {
    color: #fff;
    padding-bottom: 12px;
    text-align: left;
    margin: auto;
}

.side-panel label {
    color: #828282;
    font-size: 14px;
}

.side-panel input,
.side-panel select {
    width: 115%;
    padding: 8px;
    border-radius: 5px;
    border: none;
    margin-top: 2px;
    margin-left: 5px;
    background-color: #111010;
    color: white;
    cursor: pointer;
}

.side-panel #play-button {
    width: 115%;
    padding: 12px;
    margin-left: 5px;
    margin-bottom: 10px;
    background-color: rgb(0, 238, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.side-panel #cashout-button {
    width: 115%;
    /* margin-top: 10px; */
    margin-left: 5px;
    margin-bottom: 10px;
    padding: 12px;
    background-color: rgb(0, 238, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.amount-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Align items to the left */
    gap: 5px;
    /* Add a small gap between the input and buttons */
    margin: 0;
    margin-right: -47.5px;
    /* Ensure no additional margins are added */
}

.amount-icon {
    position: absolute;
    color: gold;
    font-size: 1.2rem;
    padding-left: 15px;
}

.amount-input {
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    padding-left: 25px !important;
}

.amount-buttons {
    display: flex;
    gap: 5px;
    /* Adjust space between buttons */
}

.amount-button {
    padding: 5px 10px;
    /* Adjust button padding */
    background-color: #00EEFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.amount-button:hover {
    background-color: #04cde7;
    transition: 0.3s ease;
}

.winnings {
    margin-left: 40%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.side-inputs {
    margin-bottom: 10px;
}

.auto-clear-btns {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 115%;
}

.side-inputs input,
.side-inputs select {
    cursor: pointer;
    background-color: #031B22;
    border-color: #0c5267;
    border-radius: 6px;
    /* padding: 6px; */
}

.side-inputs input:focus {
    outline: none;
}

/* Game Description Styles */
/* Game description card styling */
.game-description-card {
    display: flex;
    align-items: center;
    background-color: #031B22;
    border-radius: 20px;
    padding: 20px;
    color: white;
    width: 100%;
    max-width: 1202px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.game-description-image {
    width: 150px;
    height: auto;
    border-radius: 10px;
    margin-right: 20px;
}

.game-description-content {
    flex-grow: 1;
    text-align: left;
}


.game-description-header h2 {
    margin: 0;
    font-size: 18px;
}

.description-button {
    background-color: #00EEFF;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    margin-bottom: 10px;
}

.description-button:hover {
    background-color: #0056b3;
}

.game-description-content h2 {
    margin-bottom: 10px;
    color: #ffffff;
}

.game-description-content p {
    color: #c4c4c4;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 65px;
}

/* MANUAL OR AUTOPLAY STYLES */
.mode-tabs {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    margin-left: 30px;
    margin-right: -30px;
    gap: 10px;
}

.mode-tab {
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    background-color: #031116;
    color: white;
    cursor: pointer;
    border: none;
    outline: none;
    transition: background-color 0.2s ease-in-out;
}

.mode-tab:hover {
    background-color: #051d25;
}

.mode-tab.active {
    background-color: #00EEFF;
    color: black;
    font-weight: bold;
}

.manual-controls,
.auto-controls {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.auto-controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.auto-controls .amount-container {
    display: flex;
    align-items: center;
    gap: 5px;
    /* Reduced gap for buttons */
}

/* .auto-controls .amount-buttons button:hover {
    background-color: #106d89;
} */

.auto-controls .adjustment-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.auto-controls .adjustment-section select {
    flex: 1;
}

.auto-controls .adjustment-section input {
    width: 100px;
}

.reset-button {
    margin-left: 10px;
}

.mute-button {
    position: absolute;
    /* top: 345px; */
    left: 0px;
    /* Adjust spacing from the left */
    background: rgba(255, 255, 255, 0);
    border: none;
    cursor: pointer;
    font-size: 20px;
    /* Adjust the size of the icon */
    color: rgb(255, 255, 255);
    /* color: #00EEFF; */
    /* Set the color of the icon */
}

@media (max-width: 768px) {
    .game-container {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 45px auto;
        padding: 10px;
        margin-top: 100px;
    }

    .side-panel {
        width: 100%;
        padding: 10px;
        margin-left: 20px;
        order: 2;
        border-radius: 0 0 5px 5px;
    }

    .game-panel {
        width: 100% !important;
        margin: 0 auto;
        padding: 10px;
        order: 1;
        border-radius: 5px 5px 0 0 !important;
    }

    .game-container-footer {
        width: 100% !important;
        margin: 0 auto;
        order: 3;
    }

    .side-panel input,
    .side-panel select,
    .side-panel #play-button,
    .side-panel #cashout-button {
        width: 97.5%;
    }

    .game-description-card {
        width: 97.5%;
        margin-top: 10px;
        margin-bottom: -60px;
    }

    .auto-clear-btns {
        width: 99% !important;
    }
}