.error-modal {
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 1000;
    background: linear-gradient(145deg, #2c2c44, #7a2323);
    /* background: linear-gradient(145deg, #2c2c44, #1e1e2e); */
    padding: 1rem;
    border-radius: 8px;
    color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    width: 300px;
    animation: fadeIn 0.3s ease-in-out;
}

.error-modal.fade-out {
    animation: fadeOut 0.5s ease-in-out forwards;
}

.error-modal p {
    margin: 0;
}

.error-modal h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 5px;
    color: #f87171;
    display: flex;
    align-items: center;
    gap: 10px;
}

.error-modal h2::before {
    content: '!';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #f87171;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;
}

.error-modal p {
    margin: 5px 0 10px;
    font-size: 14px;
    color: #d1d5db;
}

.progress-bar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    margin: 10px auto;
}

.error-modal-progress-bar {
    height: 5px;
    border-radius: 3px;
    width: 100%;
    position: relative;
    /* animation: progressBar 5s linear forwards; */
}

.error-modal-progress-bar::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    height: 50%;
    width: 100%;
    background: #fb7185;
    border-radius: 8px;
    animation: progressBar 5s linear forwards;
}

.error-modal-close-btn {
    background: transparent;
    border: none;
    color: #d1d5db;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 10px;
}

.error-modal-close-btn:hover {
    color: #ffffff;
}

@keyframes progressBar {
    from {
        width: 100%;
        /* change this width to change width of timer bar on error */
    }

    to {
        width: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}