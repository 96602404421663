/* Dice.css */
.dice-side-panel {
    height: 37.5%;
    width: 30%;
    border-radius: 10px;
}

.dice-game-container {
    border-radius: 10px !important;
}

.dice-game-panel {
    padding-bottom: 73px;
}

.slider-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5%;
    gap: 20px;
    padding-top: 10px;
    /* margin-bottom: 25%; */
}

.slider-container {
    background-color: #073a49;
    padding: 10px;
    /* border: 5px solid #073a49;  */
    margin: auto;
    margin-bottom: 12px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 5px; */
    width: 100%;
    position: relative;
}

.winning-odds {
    display: flex;
    /* background-color: #073a49; */
    padding: 20px;
    border-radius: 10px;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 90%;
    margin-bottom: -20px;
    margin-top: 50px;
}

.wo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wo-item-input {
    width: 100%;
    color: white;
    background-color: #031B22;
    border-color: #0c5267;
    border-radius: 6px;
    padding: 6px;
}

.wo-item-input:hover {
    border-color: #106d89;
    /* transition: 0.2s ease-in-out; */
}

.wo-item input:focus {
    outline: none;
}

/* #dice-slider {
    height: 100px;
} */

.slider-container input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 12.5px;
    outline: none;
    border-color: black;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background: linear-gradient(to right, #ff0000 0%, #00ff00 100%);
    overflow: visible; /* Allows the thumb to go beyond the container */
}

.slider-container input[type="range"]::-moz-range-thumb {
    width: 30px;
    height: 30px;
    /* background: #4a90e2; */
    border-radius: 5px;
    cursor: grab;
    position: relative;
    background-image: url("./imgs/slider-test.png");
    /* transform: translateY(-4px); */
}

/* Make the slider handle larger */
.slider-container input[type="range"]::-webkit-slider-thumb {
    width: 30px;
    height: 30px;
    /* background-color: #4a90e2; */
    border-radius: 5px;
    cursor: grab;
    position: relative;
    /* transform: translateY(-4px); */
}

.slider-labels {
    margin-bottom: -10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* Match slider width */
    color: #fff;
    font-size: 16px;
    padding: 10px;
}

.slider-label {
    font-size: 14px;
    color: white;
}

.roll-outcome-display {
    position: absolute;
    top: 50px;
    /* margin: -100px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    /* padding: 10px; */
    transition: left 0.2s ease-in-out;
}

.roll-outcome-display .outcome-icon {
    font-size: 20px;
}

.roll-outcome-display .roll-value {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
    margin-top: 5px;
    /* background-color: #333333;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); */
}

.payout-container {
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
    padding: 10px;
    /* background-color: #1a1a2e; */
    border-radius: 10px;
    text-align: left;
    color: rgb(180, 180, 180);
    width: 340px;
    max-width: 340px;
    box-sizing: border-box;
    /* Include padding in width calculation */
}

.payout-heading {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.payout-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: #00EEFF;
    /* Green color for the payout value */
    display: flex;
    justify-content: left;
    align-items: center;
}

.payout-currency {
    font-size: 1.2rem;
    margin-right: 5px;
}

/* .dice-mute-button {
    top: -10px;
} */

@media (max-width: 768px) {
    .dice-side-panel {
        width: 100%;
        margin-bottom: 20px;
    }

    .controls-and-slider {
        flex-direction: column;
    }

    .winning-odds {
        flex-direction: column;
        width: 150%;
        margin-left: 40%;
    }

    .slider-container {
        width: 140%;
    }

    .slider-labels {
        width: 125%;
        margin-left: 43%;
    }
}
