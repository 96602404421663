/* Mines */
#mines-main {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mines {
    height: 650px !important;
    /* max-width: 400px; */
    /* height: 400px !important; */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.button-grid {
    width: 85%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;
    /* row-gap: 10px; */
    /* column-gap: 0px; */
    padding: 50px;
    box-sizing: border-box;
    margin: 0 50px;
}

.grid-button {
    perspective: 1000px;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.25s;
}

/* .grid-button:hover {
    border-radius: 5px;
    transform: scale(1.06);
} */

.game-started .grid-button:hover {
    border-radius: 5px;
    transform: scale(1.06);
}

.button-grid:not(.game-started) .grid-button:hover {
    transform: none;
    cursor: default;
}

.grid-button div {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: transform 0.2s;
}

.grid-button .front {
    background-color: rgb(17, 167, 177);
    color: #fff;
    transform: rotateY(0deg);
}

.grid-button .back {
    /* background-color: #222; */
    background-color: rgb(6, 48, 51);
    color: #fff;
    transform: rotateY(180deg);
}

.grid-button.flipped .front {
    transform: rotateY(180deg);
}

.grid-button.flipped .back {
    transform: rotateY(0deg);
    overflow: hidden;
}

.grid-button.exploded {
    animation: explosion 1s forwards;
    /* animation: shake 0.3s ease-in-out; */
    border: 2px solid rgb(255, 0, 0) !important;
    border-radius: 5px;
    background-color: rgba(255, 0, 0, 0.367) !important;
    overflow: hidden;
}

/* Red-to-Black Gradient Background Only When a Mine is Hit */
.grid-button.exploded.selected .back {
    /* .grid-button.exploded.selected-auto .back { */
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.1), rgba(0, 0, 0, 0.686)) !important;
    border-radius: 5px;
    border: none;
}

.grid-button.selected .back,
.grid-button.selected-auto .back {
    border: 2px solid rgb(26, 255, 0);
    /* background-color: #0062ff !important; */
    border-radius: 6px;
    /* opacity: 1; */
}

.tile-image {
    width: 100%;
    height: 110%;
    object-fit: contain;
    pointer-events: none;
}

.diamond {
    margin-top: -10px;
}

.mine {
    border-radius: 5px;
}

.payout-tracker {
    margin-top: 20px;
    background: #1a1a2e;
    border-radius: 10px;
    padding: 15px;
    color: white;
    font-size: 14px;
    width: 100%;
}

.tracker-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.center-display {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* Stack multiplier, divider, and winnings */
    justify-content: center;
    align-items: center;
    /* Center horizontally */
    text-align: center;
    /* Center text alignment */
    top: 39.5%;
    background-color: #000000b9;
    border: 3px solid #00ff00;
    border-radius: 10px;
    padding: 15px 30px;
    color: #00ff00;
    z-index: 10;
    opacity: 0;
    /* Initially hidden for fade-in */
    animation: mines-win-fadein 0.3s ease forwards;
    /* Apply fade-in animation */
}

.center-display .multiplier {
    color: #00ff00;
    font-size: 2rem;
    font-weight: bold;
}

.center-display .mines-winnings {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: normal;
}

.center-display .divider {
    width: 80%;
    height: 2px;
    background-color: #575757;
    /* Green line */
    margin: 5px 0;
    /* Space above and below the line */
}

.winnings {
    font-size: 1.2rem;
    font-weight: bold;
    color: #00EEFF;
    text-align: center;
    /* margin-top: 10px; */
    /* margin: auto; */
    /* margin-right: 20px; */
    padding: 10px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    border-radius: 8px;
}

.winnings-amount {
    color: #00ff00;
    font-weight: bold;
    font-size: 1.2rem;
}

.multiplier {
    color: #00EEFF;
    font-weight: bold;
}

.selected-auto {
    border: 2px solid yellow;
    /* background-color: purple !important; */
    border-radius: 6px;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

/* Fade-in keyframes */
@keyframes mines-win-fadein {
    from {
        opacity: 0;
        /* Start fully transparent */
        transform: scale(0.8);
        /* Slightly smaller size */
    }

    to {
        opacity: 1;
        /* Fully visible */
        transform: scale(1);
        /* Original size */
    }
}

@keyframes explosion {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        /* Reset to original size */
        opacity: 1;
        /* Keep fully visible */
    }
}

@media (max-width: 768px) {
    .mines {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 500px !important;
    }
}

@media (max-width: 456px) {
    .mines {
        height: 400px !important;
    }
}

@media (max-width: 456px) {
    .mines {
        height: 350px !important;
    }
}