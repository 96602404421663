.cardElements {
    /* border: 1px solid #ddd; */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: box-shadow 1s ease-in-out, transform 0.3s ease-in-out; /* Added transform to transition for smooth scaling */
    text-align: center;
    width: 100%; 
    max-width: 260px;
    height: 90%;
    max-height: 350px;
    border-radius: 10px; 
    overflow: hidden;
    margin: 10px 0px;
    /* min-width: 125px; */
}

.cardElements:hover {
    transform: translateY(-0.5rem); /* Scale up the container */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Add shadow for pop effect */
}

.cardElements img {
    width: 100%;
    height: 100%;
}

@media (max-width: 480px) {
    .cardElements img {
        width: 100%; /* Adjust image width to be full width of the card */
        height: 100%; /* Adjust image height to maintain aspect ratio */
    }
}
