.plinko {
    display: flex;
    flex-direction: column;
    width: 65% !important;
    align-items: center;
    background-color: #031B22;
    /* background-image: url('./imgs/plinko_background_test.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.plinko-container {
    position: relative;
    overflow: hidden;
    /* background-color: rgb(12, 12, 12) !important; */
}

/* .plinko-side-panel {
    background-color: rgb(12, 12, 12) !important;
}

.plinko-desc-card {
    background-color: rgb(8, 8, 8) !important;
} */

.plinko-canvas {
    margin-top: 20px;
    margin-bottom: 3px;
}

/* .multiplier-hit {
    position: absolute;
    top: 27.5%;
    right: 16%;
    color: yellow;
    font-size: 1.5em;
    font-weight: bold;
} */

.multiplier-hit {
    position: absolute;
    top: 10px;
    right: 10px;
    /* Keep it aligned within the container */
    z-index: 10;
    max-width: calc(100% - 20px);
    /* Ensures it doesn't overflow horizontally */
    display: flex;
    justify-content: center;
    /* background-color: #257e72; */
    align-items: center;
    padding: 10px 20px;
    /* background: linear-gradient(135deg, #ff9a9e, #fad0c4); */
    color: #fff;
    border-radius: 12px;
    /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); */
    font-weight: bold;
    font-size: 1.2em;
    /* animation: popIn 0.4s ease-in-out; */
    /* animation: bounce 0.7s infinite; */
    overflow-wrap: break-word;
    /* Handles long text properly */
}

.multiplier-hit-box {
    display: flex;
    align-items: center;
    gap: 8px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.highlight-multiplier {
    color: #00ffd9;
    font-size: 1.4em;
    font-weight: 900;
    /* text-shadow: 0 0 8px rgba(255, 235, 59, 0.8); */
}

.hit-icon {
    width: 42px;
    height: 42px;
    margin-top: 4px;
    margin-right: -6px;
    /* Add some space between the icon and text */
    vertical-align: middle;
    /* Align vertically with the text */
}

/* @keyframes bounce { BOUNCE FOR HIT MULTIPLIER 
    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
} */

@keyframes bounce {

    /* BOUNCE FOR BOX BEING HIT */
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(10px);
    }

    /* Move up */
    60% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0);
    }
}

.odds-container {
    gap: 0px;
    display: flex;
    justify-content: center;
    /* width: 82.5%; */
    /* width: 90%; */
    width: var(--container-width);
    /* Dynamic width */
    max-width: 90%;
    position: relative;
    /* margin-left: 5%; */
    margin: -20px -2px 20px 0;
}

.odds-box {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5px;
    color: black;
    transition: all 0.3s ease;
    margin: 0 4px;
    flex-grow: 1;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    /* Use the text color (or set dynamically) */
    max-width: calc(100% / var(--num-boxes));
    /* Dynamic width */
}

/* .odds-box::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 6px;
    background: currentColor;
    opacity: 0.7;
    border-radius: 4px;
} */

.odds-box.hit {
    animation: bounce 0.3s ease-in-out;
    animation-fill-mode: forwards;
}

.odds-box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    /* border: 2px solid #fff; */
    /* transform: translateY(-8px); */
    transition: all 0.3s ease-in-out;
    /* background: linear-gradient(0deg, #00eeff, #338eaa); */
    cursor: pointer;
}

.odds-box:hover::after {
    content: attr(title);
    position: absolute;
    bottom: 110%;
    left: 50%;
    /* COMMENT THIS ^^^ OUT IF I WANT IT TO MOVE AROUND */
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.85);
    color: #fff;
    /* padding: 8px 12px; */
    padding: 20px;
    border-radius: 8px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    opacity: 0.99;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    z-index: 10;
}

.odds-box:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

@keyframes bounceEffect {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.bounce {
    animation: bounceEffect 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .odds-box {
        height: 30px;
        font-size: 11px;
    }
}

@media (max-width: 468px) {
    .odds-box {
        height: 25px;
        font-size: 6px;
    }

    .odds-container {
        margin-top: 8%;
        margin-bottom: 0px;
    }
}

@media (max-width: 375px) {
    .odds-box {
        height: 16px;
        font-size: 5px;
    }

    .odds-container {
        margin-top: 10%;
    }
}